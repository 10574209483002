import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Top  from '../components/Top.vue'
import Chat from '../components/Chat.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  {
    path: '/r2',
    name: 'r2',
    component: Chat
  },
  {
    path: '/r2/:cat/:room',
    name: 'r2-cat-room',
    component: Chat
  },
  {
    path: '/r2/:cat/:room/:role',
    name: 'r2-cat-room-role',
    component: Chat
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
