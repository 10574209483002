




























import { Component, Vue } from 'vue-property-decorator';
import Message from '@/classes/Message';
import Const from '@/classes/Const';


@Component
export default class Chat extends Vue {
  private mMessages: Message[] = [];
  private mContent = '';
  private mIsConnected = false;
  private mIsStarted = false;
  private mWebSocket!: WebSocket;

  private mCategory = '';
  private mRoom     = '';
  private mRole      = -1;

  private mounted() {
//    console.log('mounted');
    this.onResize();
    window.onresize = this.onResize;
    this.mIsStarted = false;
    this.scrollToBottom();

    if (this.$route.params.cat != null) {
      this.mCategory = this.$route.params.cat;
    }
    if (this.$route.params.room != null) {
      this.mRoom = this.$route.params.room;
    }
    if (this.$route.params.role != null) {
      this.mRole = Number(this.$route.params.role);
    }
    console.log(this.mRoom);
    console.log(this.mRole);

    // 広告
    const iframe = document.createElement('iframe') as HTMLIFrameElement;
    iframe.width = '100%';
    iframe.height = '55px';
    iframe.style.border = 'none';
    const head = document.getElementsByTagName('head')[0];
    (this.$refs.ads as HTMLDivElement).appendChild(iframe);
    const iframeDocument = iframe.contentWindow!.document;
    iframeDocument.open();
    iframeDocument.write(Const.AdFooterHTML);
    iframeDocument.close();
  }
  private connect() {
    this.mWebSocket = new WebSocket('wss://o3nu3od2sg.execute-api.ap-northeast-1.amazonaws.com/Prod');
    
    this.mWebSocket.onopen = (event: Event) => {
//      console.log(event);
      this.sendConnected();
      this.mIsConnected = true;
    };
    this.mWebSocket.onmessage = (event: MessageEvent) => {
//      console.log(event);
      const data = JSON.parse(event.data);
      this.mMessages.push(new Message(data))
      
      if (data.type === 'connected') {
        this.mIsStarted = data.isStarted;
      } else if (data.type === 'disconnect') {
        // 相手から切断のケース
        this.mIsStarted = false;
        this.mWebSocket.close();
        this.mContent = '';
      }
      this.scrollToBottom();
    };
    this.mWebSocket.onerror = (event: Event) => {
      this.showSystemMessage('接続に失敗しました');
      console.error(event);
    }
    this.mWebSocket.onclose = (event: CloseEvent) => {
//      console.log(event);
      console.log(`${this.mIsConnected}, ${this.mIsStarted}`);
      this.showSystemMessage('切断されました');
      if (this.mIsConnected) {
//        this.showSystemMessage('タイムアウトしました。再接続します..');
//        console.log('reconnect');
//        this.$nextTick(() => {
//          this.connect();
//        })
      }
      this.mIsStarted = false;
      this.mIsConnected = false;
      this.mContent = '';
    }
  }
  private showSystemMessage(content: string) {
    this.mMessages.push(new Message({
      content: content,
      userName: 'system',
      ts: (new Date()).getTime(),
    }));
    this.scrollToBottom();
  }
  private showMyMessage(content: string) {
    this.mMessages.push(new Message({
      content: content,
      userName: 'self',
      ts: (new Date()).getTime(),
    }));
    this.scrollToBottom();
  }
  private sendMessage() {
//    console.log('send: ' + this.mContent);
    const content = this.mContent.trim();
    this.mContent = '';

    if (content === '') {
      return;
    }
    const data = {
      action: 'sendmessage',
      data: {
        type: 'message',
        content: content,
      },
    };
    this.mWebSocket.send(JSON.stringify(data));
    this.showMyMessage(content);
  }
  private sendConnected() {
    const data = {
      action: 'sendmessage',
      data: {
        type: 'connected',
        roomPrefix: `R2-${this.mCategory}-${this.mRoom}`,
        chatRole: this.mRole,
        content: '',
      },
    };
    this.mWebSocket.send(JSON.stringify(data));
  }
  private sendDisconnect() {
    const data = {
      action: 'sendmessage',
      data: {
        type: 'disconnect',
        content: '',
      },
    };
    this.mWebSocket.send(JSON.stringify(data));
    this.showSystemMessage('退出しました');

    this.mIsConnected = false;
    this.mIsStarted = false;
    this.mWebSocket.close();
    this.mContent = '';
  }
  private scrollToBottom() {
    this.$nextTick(() => {
      const div = this.$el.querySelector(".talk-list") as HTMLDivElement;
      if (div != null) {
        div.scrollTop = div.scrollHeight;
      }
    });
  }
  private onResize(ev?: UIEvent) {
    const div = this.$el.querySelector('.talk') as HTMLDivElement;
    if (div != null) {
      div.style.height = (window.innerHeight - 20) + 'px';
    }
  }
}
