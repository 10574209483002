







































import { Component, Vue } from 'vue-property-decorator';

import Const from '@/classes/Const';

@Component
export default class Top extends Vue {
  private mRoomsForRandomChat = Array.from(Const.RoomsForRandomChat);

  private mounted() {
    // console.log('mounted');
  }
}
