
export default class Const {
  public static readonly AdFooterHTML = '<body><script src="https://adm.shinobi.jp/s/4d80f341d26590042f0895685f40efd2"></script></body>';


  public static readonly RoomsForRandomChat = new Map<string, Map<string, string[]>>([
    ['会話', new Map<string, string[]>([
      ['雑談がしたい', []],
      ['作業の合間に会話したい', []],
      ['議論がしたい', []],
      ['悩み相談', ['悩みを相談したい', '悩み相談に乗りたい']],
      ['のろけ', ['のろけたい', 'のろけを聞きたい']],
      ['愚痴', ['グチりたい', 'グチを聞きたい']],
      ['罵り合い・口喧嘩をしたい', ['罵倒したい', '罵倒されたい']],
      ['...', []],
    ])],
    ['情報交換', new Map<string, string[]>([
      ['アニメ', []],
      ['アイドル(男)', []],
      ['アイドル(女)', []],
      ['虫', []],
      ['事務イス', []],
      ['自転車', []],
      ['...', []],
    ])],
    ['職業・ビジネス・技術', new Map<string, string[]>([
      ['エンジニア', []],
      ['医療', []],
      ['投資', []],
      ['ビジネス', []],
      ['うまい話', []],
      ['...', []],
    ])],
    ['ライフスタイル', new Map<string, string[]>([
      ['美容', []],
      ['健康', []],
      ['コロナ対策', []],
      ['...', []],
    ])],
    ['同じ番組を視聴しながら', new Map<string, string[]>([
      ['NHK', []],
      ['日テレ', []],
      ['テレ朝', []],
      ['フジテレビ', []],
      ['ラジオ', []],
      ['...', []],
    ])],
  ]);

  

}
