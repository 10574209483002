
export default class Message {
  public content = '';
  public userName = '';
  public time = '';

  constructor(data: any) {
    this.content = data.content;
    this.userName = data.userName;

    const d = new Date(data.ts);
    this.time = d.getHours() + ':' + ('0' + d.getMinutes()).substr(-2, 2); // TODO: ...
  }
}
